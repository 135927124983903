<template>
  <div class="broadcast-page">
    <div class="inner-broadcast">
      <SpinnerLoader :loading="isPartialUpdate" :float-in-corner="true" />
      <div class="broadcast-left" :class="[isThemeHeader === 'true' ? 'form-con__dark' : 'form-con', isSelectChannel ? 'w-0' : '']">
        <transition name="fade" mode="out-in">
          <div v-if="broadcastStatus === 'loading'" class="wrapper-broadcast__loader" :class="isThemeHeader === 'true' ? 'form-con__dark' : 'form-con'">
            <SpinnerLoader :loading="broadcastStatus" />
          </div>
        </transition>
        <div v-if="broadcastStatus === 'success' && !isSelectChannel" class="table table-grid">
          <div class="table-header">
            <div class="table-header-text table-header-text-broadcast d-flex" style="flex-basis: 15%; padding-left: 5px; align-items: center">
              <button class="btn-transparent mr-2" @click="getBroadcastInfo('prev', undefined, true)">
                <b-icon icon="arrow-left-square"></b-icon>
              </button>
              <button class="btn-transparent" @click="getBroadcastInfo('next', undefined, true)">
                <b-icon icon="arrow-right-square"></b-icon>
              </button>
            </div>
            <div v-for="(colDate, index) in Object.keys(gridWithEmpty)" :key="index" class="table-header-text table-header-text-broadcast">
              {{ weekDays[new Date(colDate).getUTCDay()] }} {{ colDate | convertDate }}
              <div class="text-time-seconds">{{ Object.entries(countUsedTime)[index][1].total }}/{{ Object.entries(countUsedTime)[index][1].used }}</div>
              <div class="pt-1 d-flex w-100 align-items-center justify-content-center">
                <DayDropdown
                  v-if="gridBroadcastingSettings[index]"
                  :col-date="colDate"
                  :day-broadcasting-settings="gridBroadcastingSettings[index]"
                  :channel="selects.channels"
                  @success="getBroadcastInfo(lastUsed === 'day' ? 'day' : 'update')"
                />
                <button
                  v-if="canPrepareDay"
                  class="btn-transparent mr-2"
                  :disabled="isPreparingDay"
                  :title="$t('alert.preparationDay')"
                  @click="prepareDay(colDate)"
                >
                  <b-icon font-scale="1.4" :class="isThemeHeader === 'true' ? 'white-color' : 'text-color'" icon="wrench"></b-icon>
                </button>
                <button
                  v-if="canCompactify"
                  class="btn-transparent"
                  :disabled="isCompacting"
                  :title="$t('broadcastSideBar.compactify')"
                  @click="openCompactifyDayModal(colDate)"
                >
                  <BIconArrowsCollapse font-scale="1.4" :class="isThemeHeader === 'true' ? 'white-color' : 'text-color'" />
                </button>
              </div>
            </div>
          </div>

          <div id="table-broadcast-grid" class="table-body table-body-broadcast">
            <table border class="w-100">
              <tr>
                <td style="width: 4%" :class="isThemeHeader === 'true' ? 'white-color' : 'text-color'">
                  <div id="time">
                    <template v-for="(height, hour) in scaleHour">
                      <div v-for="j in [0, 1, 2, 3, 4, 5]" :key="hour + '_' + j" class="time-hour" :style="{ height: max_scale / 6 + 'px' }">
                        {{ j === 0 ? String(hour).slice(0, 5) : '' }}
                      </div>
                    </template>
                  </div>
                </td>

                <td
                  v-for="(day, dIndex) in gridWithEmpty"
                  :key="dIndex"
                  style="width: 13.71%"
                  class="position-relative cursor-pointer"
                  :class="{ 'closed-day': checkIfDayClosed(dIndex) }"
                  @contextmenu.prevent="showModalAddEditPrRelease('add', dIndex, $event)"
                  @drop="openConfirmModal(dIndex, $event)"
                  @dragover.prevent="dragHandler"
                >
                  <div
                    v-for="(program, pIndex) in day"
                    :key="dIndex + '_' + pIndex"
                    class="program"
                    :draggable="canEditProgramRelease"
                    :class="program.program_release_id && program.program_release_start_interval ? '' : 'independent-block'"
                    :style="[
                      program.marginTop ? { marginTop: `${program.marginTop}px` } : '',
                      program.paddingBottom ? { paddingBottom: `${program.paddingBottom}px` } : '',
                    ]"
                    @contextmenu.stop.prevent="showContextModalProgram(program, $event)"
                    @drop.stop="openConfirmModal(program, $event)"
                    @dragstart="dragStart(program, $event)"
                    @dragend="dragTimePopover.show = false"
                  >
                    <div v-if="program.program_release_id && program.program_release_start_interval" class="program_header">
                      {{ sec2time(program.program_release_start_interval) + ' - ' + sec2time(program.program_release_end_interval) }}
                      <b>{{ program.program_release_program_name }}</b> {{ program.program_release_name ? ' — ' + program.program_release_name : '' }}
                    </div>

                    <div
                      v-for="(block, bIndex) in program.blocks"
                      :key="dIndex + '_' + pIndex + '_' + bIndex"
                      class="block"
                      :class="(setBlockBackground(block.block_duration, block.spot_total_duration), setBlockBorder(block.block_type_id))"
                      :draggable="canEditBlock"
                      :style="block.marginTop ? { marginTop: `${block.marginTop}px` } : ''"
                      @contextmenu.stop.prevent="showModalEditBlocks(block, $event)"
                      @dragstart.stop="dragStart(block, $event)"
                      @dragend="dragTimePopover.show = false"
                      @click="showModalBlockSpots(block.block_id, dIndex, block.block_type_id, block.auction_step_coeff)"
                    >
                      {{ sec2time(block.block_start_interval) }}
                      <div v-if="block.block_type_id == 2" class="auction-icon"><IconGavel width="15"></IconGavel></div>
                      <div class="positions start">
                        <div :class="{ spot_busy: block.spot_positions.includes('1F') }"></div>
                        <div :class="{ spot_busy: block.spot_positions.includes('2F') }"></div>
                        <div :class="{ spot_busy: block.spot_positions.includes('3F') }"></div>
                      </div>
                      <p class="text-center mb-0 font-weight-bolder">{{ block.block_duration }}/{{ showBlockSecondParam(block) }}</p>
                      <p>
                        {{ getBlockRating(block.grps, grpByDays[dIndex], selects.measurements, selects.target) }}
                        /{{ getBlockFactRating(block.fact_grps, selects.measurements, selects.target) }}
                        <span style="float: right; font-weight: bold">
                          {{
                            block.block_commercial_type_id === 1
                              ? 'CA'
                              : block.block_commercial_type_id === 2
                              ? 'SC'
                              : block.block_commercial_type_id === 3
                              ? 'RC'
                              : ''
                          }}
                        </span>
                      </p>
                      <div class="positions end">
                        <div :class="{ spot_busy: block.spot_positions.includes('3L') }"></div>
                        <div :class="{ spot_busy: block.spot_positions.includes('2L') }"></div>
                        <div :class="{ spot_busy: block.spot_positions.includes('1L') }"></div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <!--CONTEXT MODAL PROGRAM-->
        <b-modal
          ref="context-modal-program"
          hide-footer
          size="sm"
          :title="`${isAdmin && showProgramReleaseID ? '[' + contextProgram.program_release_id + ']' : ''} ${
            contextProgram.program_release_name || contextProgram.program_release_program_name
          }`"
        >
          <b-button v-if="canEditProgramRelease" block @click="showModalAddEditPrRelease('edit')">
            {{ $t('table.edit') }}
          </b-button>
          <b-button v-if="canCopyProgramRelease" block @click="showModalCopyProgramRelease()">
            {{ $t('table.copyProgramRelease') }}
          </b-button>
          <b-button v-if="canDeleteProgramRelease" block @click="showModalDeleteProgramRelease()">
            {{ $t('table.delete') }}
          </b-button>
          <b-button v-if="canCompactify" block @click="compactifySingle('release')">
            {{ $t('table.pullToPrevious') }}
          </b-button>
          <b-button v-if="canAddBlock" block @click="showModalEditBlocks()">
            {{ $t('table.addBlocks') }}
          </b-button>
        </b-modal>
        <!--CONTEXT MODAL PROGRAM-->

        <!--MODAL BLOCK ADD/EDIT-->
        <b-modal ref="edit-blocks" :title="editBlock === true ? $t('table.editBlocks') : $t('table.addNewBlocks')" :busy="isModalBusy">
          <template #default>
            <form @submit.prevent="checkIfValidThenEnter('block')">
              <input type="submit" value="Submit" class="hidden-submit" />
              <template v-if="modalEditBlock.programReleasesValue">
                <label>{{ $t('table.programRelease') }}</label>
                : {{ modalEditBlock.programReleasesValue.name ? modalEditBlock.programReleasesValue.name : '' }}

                <b-form-group :class="isThemeHeader === 'true' ? 'white-color' : 'dark-color'">
                  {{ modalEditBlock.programReleasesValue.date_start_at | convertDate }} — {{ modalEditBlock.programReleasesValue.date_end_at | convertDate }},
                  {{ modalEditBlock.programReleasesValue.time_start_at + ' - ' + modalEditBlock.programReleasesValue.time_end_at }}
                </b-form-group>
              </template>

              <b-form-group :label="$t('table.selectBlockType')">
                <MultiSelect
                  v-model.trim="$v.modalEditBlock.blockValue.$model"
                  label="name"
                  track-by="id"
                  :options="blockTypesWithoutAll"
                  :placeholder="$t('table.selectBlockType')"
                  :disabled="editBlock === true && !!spotDurationInBlock"
                ></MultiSelect>
              </b-form-group>

              <b-form-group
                v-if="$v.modalEditBlock.blockValue.$model != null && $v.modalEditBlock.blockValue.$model.id == 2"
                :label="$t('auction.coefficientStep')"
              >
                <AuctionInput v-model="modalEditBlock.auction_step_coeff" />
              </b-form-group>

              <b-form-group :label="$t('table.selectCommercialType')">
                <MultiSelect
                  v-model.trim="$v.modalEditBlock.commercialValue.$model"
                  label="name"
                  track-by="id"
                  :options="commercialTypeWithoutAll"
                  :placeholder="$t('table.selectCommercialType')"
                  :disabled="editBlock === true && !!spotDurationInBlock"
                ></MultiSelect>
              </b-form-group>

              <b-form-group :label="$t('table.date')" label-for="block-datepicker-buttons-from">
                <datepicker-wrapper id="block-datepicker-buttons-from" v-model="$v.modalEditBlock.date_start_at.$model" required />
              </b-form-group>

              <b-form-group :label="$t('table.time')" label-for="block-input-4">
                <BroadcastTimePicker
                  id="block-input-4"
                  v-model="$v.modalEditBlock.time_start_at.$model"
                  required
                  :interval-mode="true"
                  list="block-modal-start-time-templates"
                />
                <datalist id="block-modal-start-time-templates">
                  <option v-for="(t, index) in startTimeTemplates" :key="index" :value="t"></option>
                </datalist>
              </b-form-group>

              <b-form-group :label="$t('table.duration')" label-for="block-input-dur">
                <b-form-input id="block-input-dur" v-model="$v.modalEditBlock.duration.$model" type="number" min="1" required></b-form-input>
                <div class="d-flex gap-2 mt-2 align-items-center">
                  <small>{{ $t('table.templates') }}:</small>
                  <button
                    v-for="(time, index) in blockDurationTemplates"
                    :key="index"
                    type="button"
                    class="btn btn-sm btn-outline-primary"
                    @click="modalEditBlock.duration = time"
                  >
                    <small>{{ time }}</small>
                  </button>
                </div>
              </b-form-group>
              <div v-if="editBlock === true">
                <hr />
                <p class="mb-0">{{ $t('table.actions') }}</p>
                <div class="d-flex flex-row w-100 gap-3 pt-2">
                  <b-button
                    v-if="canCompactify && !modalEditBlock.programReleasesValue"
                    class="w-100"
                    size="sm"
                    :disabled="isModalBusy"
                    @click="compactifySingle('block')"
                  >
                    {{ $t('table.pullToPrevious') }}
                  </b-button>
                  <b-button v-if="canDeleteBlock" class="w-100" size="sm" :disabled="isModalBusy" @click="showModalDeleteBlocks()">
                    {{ $t('table.delete') }}
                  </b-button>
                </div>
              </div>
            </form>
          </template>

          <template #modal-footer>
            <b-button variant="outline-danger mr-2" @click="hideModalEditBlocks">
              {{ $t('table.cancel') }}
            </b-button>

            <b-button variant="danger" :disabled="$v.modalEditBlock.$invalid || isModalBusy" @click="editBlocks">
              {{ editBlock === true ? $t('table.edit') : $t('table.add') }}
            </b-button>
          </template>
        </b-modal>
        <!--MODAL BLOCK ADD/EDIT-->

        <!--MODAL DELETE BLOCK-->
        <b-modal
          ref="delete-blocks-confirm"
          size="sm"
          :title="$t('table.deleteBlocks')"
          :ok-title="$t('table.yes')"
          :cancel-title="$t('table.no')"
          auto-focus-button="ok"
          :busy="isModalBusy"
          @ok.prevent="deleteBlocks"
        >
          <p class="my-2">
            {{ $t('table.confirmDeleteBlocks') }}
            <span class="text-danger">{{ modalEditBlock.time_start_at }}</span>
            ?
          </p>
        </b-modal>
        <!--MODAL DELETE BLOCK-->

        <!--MODAL Add/Edit Program-->
        <b-modal
          id="add-edit-program"
          ref="add-edit-program"
          size="xl"
          :title="
            !modalLoading ? (modalEditPrograms.type === 'add' ? $t('broadcastSideBar.addProgramRelease') : $t('broadcastSideBar.editProgramRelease')) : ' '
          "
          :ok-disabled="$v.modalEditPrograms.$invalid || modalLoading"
          :ok-title="modalEditPrograms.type === 'add' ? $t('table.add') : $t('table.edit')"
          :cancel-title="$t('table.cancel')"
          :busy="isModalBusy"
          @ok.prevent="checkIfValidThenEnter('program')"
          @hidden="clearEditProgram()"
        >
          <SpinnerLoader v-if="modalLoading" :loading="modalLoading === true ? 'loading' : 'success'" />
          <form v-else @submit.stop.prevent="checkIfValidThenEnter('program')">
            <input type="submit" value="Submit" class="hidden-submit" />
            <datalist id="release-modal-start-time-templates">
              <option v-for="(t, index) in startTimeTemplates" :key="index" :value="t"></option>
            </datalist>
            <b-form-group v-if="modalEditPrograms.type === 'add'" v-slot="{ ariaDescribedbyAddProgram }" class="mb-4">
              <b-form-radio
                v-model="modalEditPrograms.selectedMode"
                inline
                :aria-describedby="ariaDescribedbyAddProgram"
                name="add-mode-radios"
                value="program"
              >
                {{ $t('broadcastSideBar.addOnlyProgram') }}
              </b-form-radio>
              <b-form-radio
                v-model="modalEditPrograms.selectedMode"
                inline
                :aria-describedby="ariaDescribedbyAddProgram"
                name="add-mode-radios"
                value="programBlock"
              >
                {{ $t('broadcastSideBar.addProgramWithBlock') }}
              </b-form-radio>
              <b-form-radio v-model="modalEditPrograms.selectedMode" inline :aria-describedby="ariaDescribedbyAddProgram" name="add-mode-radios" value="block">
                {{ $t('broadcastSideBar.addBlock') }}
              </b-form-radio>
            </b-form-group>
            <div v-if="modalEditPrograms.selectedMode === 'program' || modalEditPrograms.selectedMode === 'programBlock'">
              <h5 v-if="modalEditPrograms.type === 'add'" class="mb-3">{{ $t('broadcastSideBar.program') }}</h5>
              <div class="d-flex justify-content-between">
                <b-form-group style="flex-basis: 48%" :label="$t('broadcastSideBar.selectChannel')">
                  <MultiSelect
                    v-model="modalEditPrograms.channels"
                    class="mb-2"
                    :allow-empty="false"
                    :options="channel"
                    :placeholder="$t('broadcastSideBar.selectChannel')"
                    label="name"
                    track-by="id"
                    disabled
                  ></MultiSelect>
                </b-form-group>
                <b-form-group style="flex-basis: 48%" :label="$t('broadcastSideBar.selectProgram')">
                  <MultiSelect
                    v-model="modalEditPrograms.programs"
                    class="mb-2"
                    :options="programs.data"
                    :placeholder="$t('broadcastSideBar.selectProgram')"
                    label="name"
                    track-by="id"
                    :allow-empty="false"
                    @input="selectedProgramInModal"
                  ></MultiSelect>
                </b-form-group>
              </div>

              <div class="d-flex justify-content-between">
                <b-form-group
                  style="flex-basis: 48%"
                  :label="$t('table.programReleaseName') + ' (' + $t('table.optional') + ')'"
                  label-for="input-release-name"
                >
                  <b-form-input id="input-release-name" v-model.trim="modalEditPrograms.name" type="text" :placeholder="$t('table.name')"></b-form-input>
                </b-form-group>
                <b-form-group style="flex-basis: 48%" :label="$t('table.date')" label-for="input-release-date">
                  <datepicker-wrapper id="input-release-date" v-model="modalEditPrograms.dayFrom" required @input="modalEditProgramsDayFrom()" />
                </b-form-group>
              </div>

              <div class="d-flex justify-content-between">
                <b-form-group style="flex-basis: 48%" :label="$t('table.time')" label-for="input-release-time-from">
                  <BroadcastTimePicker
                    id="input-release-time-from"
                    v-model="modalEditPrograms.timeFrom"
                    required
                    :interval-mode="true"
                    list="release-modal-start-time-templates"
                    @input="modalEditProgramsTimeFrom()"
                  />
                </b-form-group>
                <b-form-group style="flex-basis: 48%" :label="$t('table.duration')" label-for="input-release-duration">
                  <BroadcastTimePicker
                    id="input-release-duration"
                    v-model="modalEditPrograms.duration"
                    :no-start-time-limit="true"
                    :max-hours="23"
                    required
                    :interval-mode="true"
                  />
                  <div class="d-flex gap-2 mt-2 align-items-center">
                    <small>{{ $t('table.templates') }}:</small>
                    <button
                      v-for="(t, index) in releaseDurationTemplates"
                      :key="index"
                      type="button"
                      class="btn btn-sm btn-outline-primary"
                      @click="modalEditPrograms.duration = t.value"
                    >
                      <small>{{ t.name }}</small>
                    </button>
                  </div>
                </b-form-group>
              </div>
            </div>

            <div
              v-if="modalEditPrograms.type === 'add' && (modalEditPrograms.selectedMode === 'block' || modalEditPrograms.selectedMode === 'programBlock')"
              class="table mt-3 mb-0"
              :class="isThemeHeader === 'true' ? 'white-color' : 'dark-color'"
            >
              <h5>{{ $t('broadcastSideBar.block') }}</h5>

              <table class="table mb-0 align-middle">
                <thead>
                  <tr>
                    <th>{{ $t('table.time') }}</th>
                    <th>{{ $t('table.duration') }}</th>
                    <th>{{ $t('table.date') }}</th>
                    <th>{{ $t('table.selectCommercialType') }}</th>
                    <th>{{ $t('table.selectBlockType') }}</th>
                    <th style="white-space: nowrap">{{ $t('auction.step') }}</th>
                    <th class="text-right"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(block, blockIndex) in modalEditPrograms.blocks" :key="blockIndex">
                    <td>
                      <b-form-group class="mb-0">
                        <BroadcastTimePicker v-model="block.timeFrom" required :interval-mode="true" list="release-modal-start-time-templates" />
                      </b-form-group>
                    </td>
                    <td>
                      <b-form-input v-model="block.duration" list="release-modal-block-durations" type="number" min="1" required></b-form-input>
                    </td>
                    <td>
                      <datepicker-wrapper
                        v-model="block.dayFrom"
                        style="height: calc(1.5em + 0.75rem + 2px)"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                        required
                        :disabled="modalEditPrograms.selectedMode === 'program' || modalEditPrograms.selectedMode === 'programBlock'"
                      />
                    </td>
                    <td>
                      <b-form-select v-model="block.commercialType" required>
                        <b-form-select-option :value="null"></b-form-select-option>
                        <b-form-select-option v-for="type in commercialTypeWithoutAll" :key="type.id" :value="type.id">
                          {{ type.name }}
                        </b-form-select-option>
                      </b-form-select>
                    </td>
                    <td>
                      <b-form-select v-model="block.blockType" required>
                        <b-form-select-option v-for="type in blockTypesWithoutAll" :key="type.id" :value="type.id">
                          {{ type.name }}
                        </b-form-select-option>
                      </b-form-select>
                    </td>
                    <td>
                      <AuctionInput v-if="block.blockType == 2" v-model="block.auction_step_coeff"></AuctionInput>
                    </td>
                    <td class="text-right align-middle">
                      <button
                        class="btn-transparent trash-hover"
                        :title="$t('table.delete')"
                        type="button"
                        :disabled="modalEditPrograms.blocks.length <= 1"
                        @click="removeBlockRowInReleaseModal(blockIndex)"
                      >
                        <b-icon icon="trash"></b-icon>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="text-right mt-1">
                <b-button type="button" size="sm" @click="addBlockRowInReleaseModal">
                  {{ $t('table.addRow') }}
                </b-button>
              </div>
              <datalist id="release-modal-block-durations">
                <option v-for="(time, ttIndex) in blockDurationTemplates" :key="ttIndex" :value="time"></option>
              </datalist>
            </div>
          </form>
        </b-modal>
        <!--MODAL Add/Edit Program-->
        <!--MODAL Add Program confirm-->
        <b-modal
          id="add-program-confirm"
          ref="add-program-confirm"
          size="sm"
          :title="
            modalEditPrograms.selectedMode === 'program'
              ? $t('broadcastSideBar.addProgram')
              : modalEditPrograms.selectedMode === 'programBlock'
              ? $t('broadcastSideBar.addProgramWithBlock')
              : $t('table.addBlocks')
          "
          :ok-title="$t('table.yes')"
          :cancel-title="$t('table.no')"
          auto-focus-button="ok"
          :busy="isModalBusy"
          @ok.prevent="AddProgram"
        >
          <div v-if="modalEditPrograms.selectedMode === 'programBlock'">
            <p>{{ $t('broadcast.confirmAddProgramBlock') }}?</p>
            <p class="mb-0">{{ $t('broadcastSideBar.program') }}</p>
            <span class="text-danger">
              {{ modalEditPrograms.dayFrom | convertDate }} {{ sec2time(modalEditPrograms.timeFrom) }} ({{ sec2time(modalEditPrograms.duration) }})
            </span>
            <p class="mb-0">{{ $t('broadcastSideBar.block') }}</p>
            <p v-for="(block, blockIndex) in modalEditPrograms.blocks" :key="blockIndex" class="text-danger mb-0">
              {{ block.dayFrom | convertDate }} {{ sec2time(block.timeFrom) }} ({{ block.duration }})
            </p>
          </div>

          <div v-if="modalEditPrograms.selectedMode === 'block'">
            <p>{{ $t('broadcast.confirmAddBlock') }}?</p>
            <p class="mb-0">{{ $t('broadcastSideBar.block') }}</p>
            <p v-for="(block, blockIndex) in modalEditPrograms.blocks" :key="blockIndex" class="text-danger mb-0">
              {{ block.dayFrom | convertDate }} {{ sec2time(block.timeFrom) }} ({{ block.duration }})
            </p>
          </div>

          <div v-if="modalEditPrograms.selectedMode === 'program'">
            <p>{{ $t('broadcast.confirmAddProgram') }}?</p>
            <p class="mb-0">{{ $t('broadcastSideBar.program') }}</p>
            <span class="text-danger">
              {{ modalEditPrograms.dayFrom | convertDate }} {{ sec2time(modalEditPrograms.timeFrom) }} ({{ sec2time(modalEditPrograms.duration) }})
            </span>
          </div>
        </b-modal>
        <!--MODAL Add Program confirm-->

        <!--MODAL DELETE  PROGRAM RELEASE-->
        <b-modal
          ref="delete-program-release-confirm"
          size="sm"
          :title="$t('table.deletePrograms')"
          :ok-title="$t('table.yes')"
          :cancel-title="$t('table.no')"
          auto-focus-button="ok"
          :busy="isModalBusy"
          @ok.prevent="deleteProgramRelease"
        >
          <template v-if="contextProgram && contextProgram.blocks">
            <p class="my-2">
              {{ $t('table.confirmDeletePrograms') }}
              <span class="text-danger">{{ contextProgram.program_release_name || contextProgram.program_release_program_name }}</span>
              ?
            </p>
            <p v-if="contextProgram.blocks.length > 1" :class="isThemeHeader === 'true' ? 'white-color mb-0' : 'dark-color mb-0'">
              <!-- If more than 1 empty div = contains blocks -->
              {{ $t('broadcast.existBlocks') + ' ' + countBlocks(contextProgram.blocks) }}!
            </p>
          </template>
        </b-modal>
        <!--MODAL DELETE PROGRAM RELEASE-->
      </div>

      <div
        v-if="isSelectChannel"
        class="broadcast-left d-flex justify-content-center align-items-center"
        :class="isThemeHeader === 'true' ? 'form-con__dark' : 'form-con'"
      >
        <div style="font-size: 18px" :class="isThemeHeader === 'true' ? 'white-color' : 'dark-color'">{{ $t('broadcastSideBar.selectChannel') }}</div>
      </div>

      <div class="broadcast-right d-flex flex-column" :class="isThemeHeader === 'true' ? 'form-con__dark' : 'form-con '">
        <div class="mt-2">
          <MultiSelect
            v-model="selects.channels"
            class="mb-2 multiselect-sm"
            :options="channel"
            :placeholder="$t('channelModal.channel')"
            label="name"
            track-by="id"
            :allow-empty="false"
          ></MultiSelect>

          <template v-if="!isSelectChannel">
            <datepicker-wrapper id="edit-program-datepicker-buttons-from_123" v-model="selects.day" size="sm" class="mb-2" dropleft required />

            <MultiSelect
              v-model="selects.measurements"
              class="mb-2 multiselect-sm"
              :options="measurementsList"
              :placeholder="$t('channelModal.measurements')"
              label="name"
              track-by="id"
              @input="updateQuery()"
            ></MultiSelect>

            <MultiSelect
              v-model="selects.target"
              class="mb-2 multiselect-sm"
              :options="targetList"
              :placeholder="$t('channelModal.target')"
              label="name"
              track-by="id"
              @input="updateQuery()"
            ></MultiSelect>

            <MultiSelect
              v-model="selects.commercial"
              class="mb-1 multiselect-sm"
              :options="commercialType"
              :allow-empty="false"
              :placeholder="$t('table.selectCommercialType')"
              label="name"
              track-by="id"
            ></MultiSelect>

            <!--
            <MultiSelect
              v-model="selects.block"
              class="mb-1 multiselect-sm"
              :options="blockTypes"
              :allow-empty="false"
              :placeholder="$t('table.selectBlockType')"
              label="name"
              track-by="id"
            ></MultiSelect>  
            -->

            <BlockSecondParamSelector v-model="secondBlockParam" />
          </template>
        </div>

        <BroadcastSideBar
          v-if="!isSelectChannel"
          ref="broadcastSideBar"
          :last-used="lastUsed"
          :channel="selects.channels"
          :measurement="selects.measurements"
          :target="selects.target"
          :day="selects.day"
          :commercial="selects.commercial"
          :block="selects.block"
          :arrow-count="count"
          :start-day="lastUsed === 'day' ? selects.day : firstDay"
          :exporting="exporting"
          :start-week-day="lastUsed === 'day' ? firstDays : firstDay"
          @addProgram="getBroadcastInfo"
          @exportReport="exportReport"
          @showModalAddEditPrRelease="showModalAddEditPrRelease"
        />
        <div class="grid-wrapper__btn mt-auto w-100 mb-2">
          <b-dropdown
            v-if="canEditProgramRelease && canEditBlock"
            :text="$t('table.settings')"
            variant="outline-danger"
            size="sm"
            right
            lazy
            style="grid-column: span 2"
          >
            <b-dropdown-form>
              <b-form-checkbox v-model="showDragAndDropConfirmationModal" switch>
                {{ $t('table.showDragAndDropConfirmationModal') }}
              </b-form-checkbox>
            </b-dropdown-form>
          </b-dropdown>
          <b-button v-if="$checkPermissions('broadcasting.closed_months')" v-b-modal.closing-months-modal size="sm">
            {{ $t('broadcast.closingMonths') }}
          </b-button>

          <router-link :to="{ name: 'profile' }" class="btn btn-outline-danger btn-sm">
            {{ $t('broadcastSideBar.exit') }}
          </router-link>
        </div>
        <ModalClosingMonths
          :broadcast-start-date="lastUsed === 'day' ? firstDays : firstDay"
          @update="getBroadcastInfo(lastUsed === 'day' ? 'day' : 'update')"
        />
      </div>
    </div>

    <!-- MODAL BLOCK SPOTS -->
    <ModalBlockSpots
      :block-id="blockId"
      :block-type="blockType"
      :block-step="blockStep"
      :closed-day="blockFromClosedDay"
      layout="broadcast"
      @postSpot="getBroadcastInfo(lastUsed === 'day' ? 'day' : 'update')"
    />
    <!-- MODAL BLOCK SPOTS -->

    <!-- CONFIRM D&D -->
    <b-modal
      id="modal-confirm-drag-and-drop"
      ref="modal-confirm-drag-and-drop"
      size="sm"
      :title="$t('table.confirmDrop')"
      :ok-disabled="$v.confirmModal.$invalid"
      :ok-title="$t('table.yes')"
      :cancel-title="$t('table.no')"
      auto-focus-button="ok"
      @ok="confirmDragAndDrop"
    >
      <p>
        {{ $t('table.confirmDrop') }}
        <span class="text-danger">{{ confirmModal.name }}</span>
        ({{ confirmModal.dateFrom | convertDate }} {{ sec2time(confirmModal.timeFrom) }}) {{ $t('table.on') }} {{ confirmModal.dateOn | convertDate }}
      </p>
      <BroadcastTimePicker
        id="input-d-a-d"
        v-model="confirmModal.time"
        required
        :interval-mode="true"
        list="confirm-dad-modal-start-time-templates"
        @keyup.native.enter="confirmDragAndDrop"
      />
      <datalist id="confirm-dad-modal-start-time-templates">
        <option v-for="(t, index) in startTimeTemplates" :key="index" :value="t"></option>
      </datalist>
    </b-modal>

    <!-- CONFIRM COMPACTIFY -->
    <b-modal
      id="modal-confirm-compactify"
      ref="modal-confirm-compactify"
      :title="'[BETA] ' + $t('broadcastSideBar.compactify')"
      :ok-disabled="isCompacting"
      :ok-title="$t('table.yes')"
      :cancel-title="$t('table.no')"
      auto-focus-button="ok"
      @ok.prevent="compactifyDay"
    >
      <p>
        {{ $t('broadcast.confirmCompactify') }}
        <span class="text-danger"> {{ compactDay | convertDate }} </span>?
      </p>
      <b-form-group v-slot="{ ariaDescribedByStartForCompactify }" :label="$t('table.start_at')">
        <b-form-radio
          v-model="isCompactFromDayStart"
          :aria-describedby="ariaDescribedByStartForCompactify"
          name="check-button-compactify-start"
          :value="false"
          :disabled="isCompacting"
        >
          {{ $t('table.fromFirstBlockOrRelease') }}
        </b-form-radio>
        <b-form-radio
          v-model="isCompactFromDayStart"
          :aria-describedby="ariaDescribedByStartForCompactify"
          name="check-button-compactify-start"
          :value="true"
          :disabled="isCompacting"
        >
          {{ $t('table.fromDayStart') }} ({{ sec2time(BROADCAST_START_TIME_IN_SEC) }})
        </b-form-radio>
      </b-form-group>
    </b-modal>

    <!-- COPY PROGRAM RELEASE -->
    <b-modal
      id="modal-copy-program-release"
      ref="modal-copy-program-release"
      size="sm"
      :title="$t('table.copyProgramRelease')"
      :ok-disabled="$v.modalCopyRelease.$invalid"
      :ok-title="$t('table.yes')"
      :cancel-title="$t('table.no')"
      auto-focus-button="ok"
      @ok="copyProgramRelease"
    >
      <form @submit.prevent="checkInCopyProgramModal">
        <p v-if="modalCopyRelease.program_release" class="text-danger">
          {{ modalCopyRelease.program_release.program_release_program_name }}<br />
          {{ modalCopyRelease.program_release ? modalCopyRelease.program_release.program_release_name : '-' }}
        </p>
        <b-form-group :label="$t('table.day')" label-for="c-p-r-datepicker-buttons-from">
          <datepicker-wrapper id="c-p-r-datepicker-buttons-from" v-model="modalCopyRelease.date_to" required />
        </b-form-group>
        <b-form-group :label="$t('table.time')" label-for="input-c-p-r-time">
          <BroadcastTimePicker
            id="input-c-p-r-time"
            v-model="modalCopyRelease.time_to"
            required
            :interval-mode="true"
            list="copy-release-modal-start-time-templates"
          />
          <datalist id="copy-release-modal-start-time-templates">
            <option v-for="(t, index) in startTimeTemplates" :key="index" :value="t"></option>
          </datalist>
        </b-form-group>
      </form>
    </b-modal>
    <div v-show="dragTimePopover.show" id="drag-time-popover" class="border-bottom border-danger shadow-sm"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, minValue, between, maxValue, requiredIf } from 'vuelidate/lib/validators';
import MultiSelect from '@/components/MultiSelect';
import errorsHandler from '@/utils/errorsHandler';
import SpinnerLoader from '../components/SpinnerLoader';
import convertDate from '../filters/convertDate';
import placementGrid from '../mixins/placementGrid';
import ModalBlockSpots from '@/components/Booking/ModalBlockSpots';
import marketingBroadcastQueryProps from '../mixins/marketingBroadcastQueryProps';
import downloadFileHandler from '@/mixins/downloadFileHandler';
import getBlockRatings from '@/mixins/getBlockRatings';
import DatepickerWrapper from '@/components/DatepickerWrapper.vue';
import { BIconArrowsCollapse, BDropdown, BDropdownForm } from 'bootstrap-vue';
import showBlockSecondParam from '@/mixins/showBlockSecondParam';
import BroadcastTimePicker from '@/components/BroadcastTimePicker';
import DayDropdown from '@/components/Broadcast/DayDropdown.vue';
import { sec2time } from '@/utils/timeConverters';
import throttle from '@/utils/trottle';
import AuctionInput from '@/components/AuctionInput.vue';

const envMinHourInSec = process.env.VUE_APP_BROADCAST_START_TIME ? +process.env.VUE_APP_BROADCAST_START_TIME : 21600; //default 06:00:00
const enfOfBroadcastDay = envMinHourInSec + 24 * 60 * 60 - 1;
const startInBroadcastTimeRange = (value) => (value ? value <= enfOfBroadcastDay && envMinHourInSec <= value : false);

const startTimePresets = [];
for (let i = envMinHourInSec; i < enfOfBroadcastDay; i += 5 * 60) {
  startTimePresets.push(sec2time(i));
}

const showDnDCM = localStorage.getItem('showDragAndDropConfirmationModal') === 'false' ? false : true;

export default {
  name: 'Broadcast',
  components: {
    SpinnerLoader,
    BroadcastSideBar: () => import('@/components/Broadcast/BroadcastSideBar'),
    MultiSelect,
    ModalBlockSpots,
    DatepickerWrapper,
    ModalClosingMonths: () => import('@/components/Broadcast/ModalClosingMonths'),
    BlockSecondParamSelector: () => import('@/components/BlockSecondParamSelector'),
    BroadcastTimePicker,
    BIconArrowsCollapse,
    DayDropdown,
    BDropdown,
    BDropdownForm,
    AuctionInput,
    IconGavel: () => import('@/components/icons/IconGavel.vue'),
  },
  filters: {
    convertDate,
  },
  mixins: [placementGrid, marketingBroadcastQueryProps, downloadFileHandler, getBlockRatings, showBlockSecondParam],
  props: {
    channel_id: { type: [String, Number], default: undefined },
    date: { type: String, default: undefined },
    measurement_id: { type: [String, Number], default: undefined },
    target_id: { type: [String, Number], default: undefined },
    commercial_type_id: { type: [String, Number], default: undefined },
    block_type_id: { type: [String, Number], default: undefined },
  },
  data() {
    return {
      lastDay: '',
      isSelectChannel: true,
      selects: {
        commercial: '',
        block: '',
        channels: '',
        measurements: '',
        target: '',
        day: '',
      },
      contextProgram: {},
      modalEditBlock: {
        value: '',
        options: [],
        date_start_at: '',
        time_start_at: '',
        duration: '',
        commercialValue: {},
        blockValue: {},
        auction_step_coeff: null,
        commercialOptions: [],
        programReleasesValue: {},
      },
      editBlock: false,
      spotDurationInBlock: 0,
      modalEditPrograms: {
        type: 'add',
        selectedMode: 'program',
        channels: {},
        programs: {},
        programRelease: null,
        name: null,
        dayFrom: '',
        duration: '',
        timeFrom: '',
        blocks: [
          {
            commercialType: '',
            dayFrom: '',
            timeFrom: '',
            duration: '',
            blockType: 1,
            auction_step_coeff: 0,
          },
        ],
      },
      draggingItem: {},
      lastUsed: '',
      isDraggingProgram: false,
      blockId: undefined,
      blockStep: undefined,
      blockType: undefined,
      blockFromClosedDay: false,

      confirmModal: {
        name: '',
        time: '',
        event: '',
        item: '',
        dateOn: '',
        dateFrom: '',
        timeFrom: '',
      },

      modalCopyRelease: {
        program_release: '',
        date_to: '',
        time_to: '',
      },
      exporting: false,
      modalLoading: true,
      isModalBusy: false,
      isPreparingDay: false,
      isPartialUpdate: '',

      isCompacting: false,
      isCompactFromDayStart: false,
      compactDay: '',

      releaseDurationTemplates: [
        { name: '00:14:59', value: 14 * 60 + 59 },
        { name: '00:29:59', value: 29 * 60 + 59 },
        { name: '00:44:59', value: 44 * 60 + 59 },
        { name: '00:59:59', value: 59 * 60 + 59 },
        { name: '01:29:59', value: 1 * 60 * 60 + 29 * 60 + 59 },
        { name: '01:59:59', value: 1 * 60 * 60 + 59 * 60 + 59 },
      ],
      startTimeTemplates: startTimePresets,
      blockDurationTemplates: [180, 240, 300, 360, 420, 480],

      dragTimePopover: {
        show: false,
        offsetX: 0,
        offsetY: 0,
        popoverElement: null,
      },
      showDragAndDropConfirmationModal: showDnDCM,
    };
  },
  validations() {
    const prRelease = {
      channels: { required },
      programs: { required },
      dayFrom: { required },
      timeFrom: { required, startInBroadcastTimeRange },
      duration: { required, minValue: minValue(1) },
    };
    const modalEditBlock = {
      value: { required },
      date_start_at: { required },
      time_start_at: { required, startInBroadcastTimeRange },
      duration: { required, minValue: minValue(1) },
      commercialValue: { required },
      blockValue: { required },
      auction_step_coeff: {
        required,
        minValue: minValue(0),
        maxValue: maxValue(100),
      },
    };
    const confirmModal = {
      time: { required, startInBroadcastTimeRange },
    };
    const modalCopyRelease = {
      program_release: { required },
      date_to: { required },
      time_to: { required, startInBroadcastTimeRange },
    };
    if (this.modalEditPrograms.selectedMode === 'programBlock') {
      return {
        modalEditBlock: { ...modalEditBlock },
        confirmModal,
        modalCopyRelease,
        modalEditPrograms: {
          ...prRelease,
          blocks: {
            $each: {
              commercialType: { required },
              dayFrom: { required },
              timeFrom: {
                required,
                startInBroadcastTimeRange,
                between: between(this.modalEditPrograms.timeFrom, this.modalEditPrograms.timeFrom + this.modalEditPrograms.duration),
              },
              duration: { required, minValue: minValue(1) },
              blockType: { required },
              auction_step_coeff: { required, minValue: minValue(0), maxValue: maxValue(100) },
            },
          },
        },
      };
    } else if (this.modalEditPrograms.selectedMode === 'block') {
      return {
        modalEditBlock: { ...modalEditBlock },
        confirmModal,
        modalCopyRelease,
        modalEditPrograms: {
          blocks: {
            $each: {
              commercialType: { required },
              dayFrom: { required },
              timeFrom: {
                required,
                startInBroadcastTimeRange,
              },
              duration: { required, minValue: minValue(1) },
              blockType: { required },
              auction_step_coeff: { required, minValue: minValue(0), maxValue: maxValue(100) },
            },
          },
        },
      };
    } else if (this.modalEditPrograms.selectedMode === 'program') {
      return {
        modalEditBlock: { ...modalEditBlock },
        confirmModal,
        modalCopyRelease,
        modalEditPrograms: {
          ...prRelease,
        },
      };
    }
  },
  computed: {
    ...mapGetters({
      isLocale: 'isLocale',
      broadcastData: 'getBroadcast',
      broadcastStatus: 'getBroadcastStatus',
      isThemeHeader: 'isTheme',
      target: 'getTarget',
      channel: 'getChannel',
      measurementsList: 'getMeasurementsList',
      targetList: 'getTargetList',
      commercialType: 'getCommercialType',
      commercialTypeWithoutAll: 'getCommercialTypeWithoutAll',
      blockTypes: 'getBlockTypes',
      blockTypesWithoutAll: 'getblockTypesWithoutAll',
      modalEditProgramReleases: 'getModalEditProgramReleases',
      programs: 'getPrograms',
      spots: 'getBlockSpotsList',
      commercialVersionTypes: 'getCommercialVersionTypes',
      placementType: 'getPlacementType',
      isAdmin: 'getIsCurrentUserAdmin',
      showProgramReleaseID: 'getShowProgramReleaseID',
    }),
    canAddProgramRelease() {
      return this.$checkPermissions('program_release.create');
    },
    canEditProgramRelease() {
      return this.$checkPermissions('program_release.update');
    },
    canDeleteProgramRelease() {
      return this.$checkPermissions('program_release.delete');
    },
    canAddBlock() {
      return this.$checkPermissions('block.create');
    },
    canEditBlock() {
      return this.$checkPermissions('block.update');
    },
    canDeleteBlock() {
      return this.$checkPermissions('block.delete');
    },
    canReadSpotList() {
      return this.$checkPermissions('booking.block');
    },
    canPrepareDay() {
      return this.$checkPermissions('broadcasting.channel_preparation');
    },
    canWorkInClosedDay() {
      return this.$checkPermissions('channel.operations_closed_days');
    },
    canCopyProgramRelease() {
      return this.$checkPermissions('broadcasting.channel_copy_program_release');
    },
    canCompactify() {
      return this.$checkPermissions('program_release.update', 'block.update');
    },
  },
  watch: {
    async 'selects.measurements'() {
      if (!this.isSelectChannel) {
        this.selects.target = '';
        if (this.selects.measurements) {
          await this.$store.dispatch('GET_TARGET', {
            'filter[measurement_company_id]': this.selects.measurements.id,
          });
        }
      }
    },
    async 'selects.channels'() {
      if (this.selects.channels) {
        this.count = 0;
        await this.getBroadcastInfo(this.lastUsed === 'day' ? 'day' : 'update', undefined, true);
      }
      this.modalEditPrograms.blocks[0].auction_step_coeff = this.defaultAuctionStepCoefficient();
    },
    broadcastStatus() {
      if (this.broadcastStatus === 'success') {
        this.isSelectChannel = false;
      }
    },
    async 'selects.commercial'() {
      if (!this.isSelectChannel) {
        await this.getBroadcastInfo(this.lastUsed === 'day' ? 'day' : 'update');
      }
    },
    async 'selects.block'() {
      if (!this.isSelectChannel) {
        await this.getBroadcastInfo(this.lastUsed === 'day' ? 'day' : 'update');
      }
    },
    async 'selects.day'() {
      if (!this.isSelectChannel) {
        await this.getBroadcastInfo('day', undefined, true);
        this.lastUsed = 'day';
        this.modalEditPrograms.blocks[0].auction_step_coeff = this.defaultAuctionStepCoefficient();
      }
    },
    async 'modalEditBlock.value'() {
      this.modalEditBlock.programReleasesValue = {};
    },

    showDragAndDropConfirmationModal(value) {
      localStorage.setItem('showDragAndDropConfirmationModal', value);
    },
  },
  created() {
    document.title = this.$i18n.t('sideBar.broadcast') + ' – OpenMediaLogic';
  },
  mounted() {
    this.getSelectInfo();
  },
  destroyed() {
    this.$store.commit('clearTargetsList');
    this.$store.commit('clearBroadcast');
  },
  methods: {
    defaultAuctionStepCoefficient() {
      let year = this.selects.day.substring(0, 4);
      if (this.selects.channels?.channel_settings == null || this.selects.channels?.channel_settings[year] == null) return 0.05;
      return this.selects.channels?.channel_settings[year]?.auction_step_coeff;
    },

    showModalCopyProgramRelease() {
      this.modalCopyRelease.program_release = this.contextProgram;
      this.modalCopyRelease.time_to = this.contextProgram.program_release_start_interval;
      this.modalCopyRelease.date_to = this.contextProgram.program_release_broadcast_day;
      this.$refs['context-modal-program'].hide();
      this.$refs['modal-copy-program-release'].show();
    },

    checkInCopyProgramModal() {
      if (this.modalCopyRelease.time_to && this.modalCopyRelease.date_to) this.copyProgramRelease();
    },
    async copyProgramRelease() {
      await this.$store.dispatch('POST_BROADCAST_COPY_PROGRAM_RELEASE', {
        channel_id: this.selects.channels.id,
        date: this.modalCopyRelease.program_release.program_release_id,
        formData: {
          date_to: this.modalCopyRelease.date_to,
          start_interval: this.modalCopyRelease.time_to,
        },
        handler: (res) => {
          this.$notify({
            type: 'success',
            title: this.$i18n.t('alert.programReleaseCopied'),
            text: res?.data?.data?.message,
          });
          this.modalCopyRelease.program_release = '';
          this.modalCopyRelease.date_to = '';
          this.modalCopyRelease.time_to = '';
          this.$refs['modal-copy-program-release'].hide();
          this.getBroadcastInfo(this.lastUsed === 'day' ? 'day' : 'update');
        },
        handlerError: (errors) => {
          errorsHandler(errors, this.$notify);
        },
      });
    },

    async getBroadcastInfo(date, clickPos, loader) {
      if (!loader) this.isPartialUpdate = 'loading';
      this.setCountAndDates(date);

      if (clickPos) this.getPosY(clickPos);
      if (loader) this.$store.commit('getBroadcastRequest');

      await this.$store.dispatch('GET_BROADCAST', {
        data: {
          channel: this.selects.channels.id,
        },
        date: {
          date_start_at: date === 'day' ? this.firstDays : this.firstDay,
          date_end_at: date === 'day' ? this.lastDays : this.lastDay,
          commercial_type_id: this.selects.commercial.id || null,
          block_type_id: this.selects.block.id || null,
        },
      });
      await this.buildGrid();
      this.modalEditBlock.value = this.selects.channels;
      this.updateQuery();

      this.$store.commit('cleanProgramReleases');
      this.isPartialUpdate = '';
    },

    async getSelectInfo() {
      await Promise.all([
        this.measurementsList.length < 1 ? this.$store.dispatch('GET_MEASUREMENTS', { per_page: 1000 }) : undefined,
        this.channel.length < 1 ? this.$store.dispatch('GET_CHANNEL', { per_page: 1000, include: 'channelSettings' }) : undefined,
        this.commercialType.length < 1 ? this.$store.dispatch('GET_COMMERCIAL_TYPE') : undefined,
        this.blockTypes.length < 1 ? this.$store.dispatch('GET_BLOCK_TYPES') : undefined,
      ]);
      this.loadProps();
    },

    countBlocks(blocks) {
      let count = 0;
      for (let block in blocks) {
        if (Object.prototype.hasOwnProperty.call(blocks[block], 'block_duration')) {
          count++;
        }
      }
      return count;
    },

    checkIfValidThenEnter(param) {
      switch (param) {
        case 'block':
          if (!this.$v.modalEditBlock.$invalid && !this.isModalBusy) this.editBlocks();
          break;
        case 'program':
          if (!this.$v.modalEditPrograms.$invalid) {
            if (this.modalEditPrograms.type === 'add') this.showModalAddProgramConfirm();
            else if (!this.isModalBusy) this.EditProgram();
          }
          break;
        default:
          break;
      }
    },

    dragStart(item, e) {
      if (!this.canEditBlock || !this.canEditProgramRelease) return;
      this.draggingItem = {};
      e.dataTransfer.dropEffect = 'move';
      e.dataTransfer.effectAllowed = 'move';
      if (item.program_release_id) {
        this.isDraggingProgram = true;
        this.contextProgram = item;
        this.confirmModal.dateFrom = item.program_release_broadcast_day;
        this.confirmModal.timeFrom = item.program_release_start_interval;
      } else {
        //block
        this.isDraggingProgram = false;
        this.draggingItem = item;
        this.confirmModal.dateFrom = item.block_broadcast_day;
        this.confirmModal.timeFrom = item.block_start_interval;
      }

      //set offset for popover
      const targetBCR = e.target.getBoundingClientRect();
      const posY = e.clientY;
      const posX = e.clientX;
      this.dragTimePopover.offsetX = targetBCR.left - posX;
      this.dragTimePopover.offsetY = targetBCR.top - posY;
      this.dragTimePopover.popoverElement = document.getElementById('drag-time-popover');
      if (this.dragTimePopover.popoverElement) {
        // set start properties for time popover
        this.dragTimePopover.popoverElement.style.width = e.target.offsetWidth + 'px';
        this.dragTimePopover.popoverElement.style.top = +e.clientY - this.dragTimePopover.popoverElement.offsetHeight + 'px';
        this.dragTimePopover.popoverElement.style.left = +e.clientX + 'px';
      }
    },

    dragHandler: throttle(function (e) {
      // show time popover above the dragging element
      const tableScrollDiv = document.getElementById('table-broadcast-grid');
      const offsetpos = this.recursive_offset(tableScrollDiv);
      //get time from top of the dragging element
      const posY = e.clientY + offsetpos.y + this.dragTimePopover.offsetY;
      const timeOfClick = this.timeOfClick(posY);
      //place popover above the dragging element & update time text
      if (this.dragTimePopover.popoverElement) {
        this.dragTimePopover.popoverElement.style.top = +e.clientY + this.dragTimePopover.offsetY - this.dragTimePopover.popoverElement.offsetHeight + 'px';
        this.dragTimePopover.popoverElement.style.left = +e.clientX + this.dragTimePopover.offsetX + 'px';
        this.dragTimePopover.popoverElement.innerText = timeOfClick;
      }
      this.dragTimePopover.show = true;
    }, 100),

    openConfirmModal(item, e) {
      this.getPosY(e);
      //get time from top of the dragging element
      const timeOfClick = this.time2sec(this.timeOfClick(this.posY + this.dragTimePopover.offsetY));
      this.confirmModal.name = this.isDraggingProgram
        ? this.contextProgram.program_release_name || this.contextProgram.program_release_program_name
        : this.$i18n.t('table.block-a');
      this.confirmModal.time = timeOfClick;
      this.confirmModal.event = e;
      this.confirmModal.item = item;

      if (this.isDraggingProgram === false) {
        // BLOCK DRAG-AND-DROP
        this.editBlock = true;
        this.modalEditBlock.id = this.draggingItem.block_id;
        this.modalEditBlock.time_start_at = timeOfClick;
        this.modalEditBlock.duration = this.draggingItem.block_duration;

        if (typeof item !== 'string') {
          // Drop into program release
          this.modalEditBlock.commercialValue.id = this.draggingItem.block_commercial_type_id;
          this.modalEditBlock.blockValue.id = this.draggingItem.block_type_id;
          this.modalEditBlock.auction_step_coeff = this.draggingItem.auction_step_coeff;
          this.modalEditBlock.programReleasesValue = {};
          this.modalEditBlock.programReleasesValue.id = item.program_release_id;
          if (item.program_release_broadcast_day) this.modalEditBlock.date_start_at = item.program_release_broadcast_day;
          else if (item.blocks && item.blocks.length > 0) this.modalEditBlock.date_start_at = item.blocks[0].block_broadcast_day;
          else this.modalEditBlock.date_start_at = this.draggingItem.block_broadcast_day; // CHECK FOR DROP IN INDEPENDENT BLOCK
        } else {
          // Move in an empty place in a day
          this.modalEditBlock.date_start_at = item;
          this.modalEditBlock.commercialValue.id = this.draggingItem.block_commercial_type_id;
          this.modalEditBlock.blockValue.id = this.draggingItem.block_type_id;
          this.modalEditBlock.programReleasesValue = {};
          this.modalEditBlock.programReleasesValue.id = null;
        }
      } else {
        // PROGRAM DRAG-AND-DROP
        let objectDate = '';

        if (typeof item !== 'string') {
          // Droped into program, get date from it
          objectDate = item.program_release_broadcast_day || item.blocks[0].block_broadcast_day;
          this.modalEditPrograms.dayFrom = objectDate;
        } else {
          // Droped into empty place in grid day
          this.modalEditPrograms.dayFrom = item;
        }
        this.modalEditPrograms.channels = this.selects.channels;
        this.modalEditPrograms.programs.id = this.contextProgram.program_release_program_id;
        this.modalEditPrograms.name = this.contextProgram.program_release_name;
        this.modalEditPrograms.timeFrom = timeOfClick;
        this.modalEditPrograms.duration = this.contextProgram.program_release_broadcast_duration;
      }

      this.confirmModal.dateOn = this.isDraggingProgram ? this.modalEditPrograms.dayFrom : this.modalEditBlock.date_start_at;

      // show if the confirmation modal is on else -> edit block\release
      if (this.showDragAndDropConfirmationModal) this.$bvModal.show('modal-confirm-drag-and-drop');
      else this.onDrop(this.confirmModal.event);
    },

    confirmDragAndDrop() {
      if (this.confirmModal.time) {
        this.$refs['modal-confirm-drag-and-drop'].hide();
        this.onDrop(this.confirmModal.event);
      }
    },

    async onDrop(e) {
      this.getPosY(e);

      if (this.isDraggingProgram === false) {
        // BLOCK DRAG-AND-DROP
        this.modalEditBlock.time_start_at = this.confirmModal.time;
        await this.editBlocks();
      } else {
        // PROGRAM DRAG-AND-DROP
        this.modalEditPrograms.timeFrom = this.confirmModal.time;
        await this.EditProgram();
      }
    },

    showContextModalProgram(program, event) {
      if (!this.canEditProgramRelease) return;
      // open block menu if it's dummy program release of independent block
      if (program.program_release_id === null) {
        if (program.blocks && program.blocks[0]) this.showModalEditBlocks(program.blocks[0], event);
        return;
      }
      this.getPosY(event);
      const timeOfClick = this.time2sec(this.timeOfClick(this.posY));
      this.contextProgram = {};
      this.contextProgram = program;
      this.contextProgram.clickTime = timeOfClick;

      this.$refs['context-modal-program'].show();
    },
    hideContextModalProgram() {
      this.$refs['context-modal-program'].hide();
    },

    clearModalEditBlocksFields() {
      this.modalEditBlock.date_start_at = '';
      this.modalEditBlock.duration = '';
      //this.modalEditBlock.value = "";
      this.modalEditBlock.commercialValue = {};
      this.modalEditBlock.blockValue = {};
      this.modalEditBlock.auction_step_coeff = this.defaultAuctionStepCoefficient();
      this.modalEditBlock.programReleasesValue = {};
      this.modalEditBlock.time_start_at = '';
    },

    async showModalEditBlocks(block, event) {
      this.clearModalEditBlocksFields();
      if (typeof block !== 'undefined') {
        // if block edit
        this.getPosY(event);
        if (block.block_program_release_id) {
          await this.$store.dispatch('GET_PROGRAM_RELEASES_ID', block.block_program_release_id);
          this.modalEditBlock.programReleasesValue = this.modalEditProgramReleases;
        } else {
          this.modalEditBlock.programReleasesValue = null; // for independent block
        }
        this.editBlock = true;
        this.spotDurationInBlock = block.spot_total_duration;
        this.modalEditBlock.id = block.block_id;
        this.modalEditBlock.date_start_at = block.block_broadcast_day;
        this.modalEditBlock.duration = block.block_duration;
        this.modalEditBlock.time_start_at = block.block_start_interval;
        //this.modalEditBlock.value = this.selects.channels;
        this.modalEditBlock.commercialValue = this.commercialType.find((type) => type.id === block.block_commercial_type_id);
        this.modalEditBlock.blockValue = this.blockTypes.find((type) => type.id === block.block_type_id);
        this.modalEditBlock.auction_step_coeff = block.auction_step_coeff ?? '';
      } else {
        // add new block in pr. release
        this.hideContextModalProgram();
        this.modalEditBlock.date_start_at = this.contextProgram.program_release_broadcast_day;
        this.modalEditBlock.time_start_at = this.contextProgram.clickTime;
        this.modalEditBlock.programReleasesValue = {
          date_start_at: this.contextProgram.program_release_broadcast_day,
          date_end_at: this.contextProgram.program_release_broadcast_day,
          time_start_at: this.contextProgram.program_release_start_interval,
          time_end_at: this.contextProgram.program_release_end_interval,
          program_id: this.contextProgram.program_release_program_id,
          id: this.contextProgram.program_release_id,
          duration: this.contextProgram.program_release_broadcast_duration,
          name: this.contextProgram.program_release_name,
        };
        this.spotDurationInBlock = 0;
        this.editBlock = false;
        this.modalEditBlock.blockValue = this.blockTypes.find((type) => type.id === 1);
        this.modalEditBlock.auction_step_coeff = this.defaultAuctionStepCoefficient();
      }
      this.$refs['edit-blocks'].show();
    },
    hideModalEditBlocks() {
      this.$refs['edit-blocks'].hide();
    },
    async editBlocks() {
      this.isModalBusy = true;
      const formData = {
        channel_id: this.modalEditBlock.value.id,
        program_release_id: this.modalEditBlock.programReleasesValue ? this.modalEditBlock.programReleasesValue.id : null,
        commercial_type_id: this.modalEditBlock.commercialValue.id,
        block_type_id: this.modalEditBlock.blockValue.id,
        auction_step_coeff: this.modalEditBlock.auction_step_coeff,
        broadcast_day: this.modalEditBlock.date_start_at,
        start_interval: this.modalEditBlock.time_start_at,
        duration: this.modalEditBlock.duration,
      };
      if (this.editBlock === true) {
        const blockId = this.modalEditBlock.id;
        await this.$store.dispatch('PUT_BLOCKS', {
          blockId,
          formData,
          handler: () => {
            this.$notify({
              type: 'success',
              title: this.$i18n.t('alert.editBlocks'),
              text: this.sec2time(this.modalEditBlock.time_start_at),
            });
            this.hideModalEditBlocks();
            this.clearModalEditBlocksFields();
            this.getBroadcastInfo(this.lastUsed === 'day' ? 'day' : 'update');
          },
          handlerError: (errors) => {
            errorsHandler(errors, this.$notify);
          },
        });
      } else {
        await this.$store.dispatch('POST_BLOCKS', {
          formData,
          handler: () => {
            this.$notify({
              type: 'success',
              title: this.$i18n.t('alert.addBlocks'),
              text: this.sec2time(this.modalEditBlock.time_start_at),
            });
            this.hideModalEditBlocks();
            this.clearModalEditBlocksFields();
            this.getBroadcastInfo(this.lastUsed === 'day' ? 'day' : 'update');
          },
          handlerError: (errors) => {
            errorsHandler(errors, this.$notify);
          },
        });
      }
      this.isModalBusy = false;
    },

    // modal delete block

    async showModalDeleteBlocks() {
      this.$refs['delete-blocks-confirm'].show();
    },
    hideModalDeleteBlocksConfirm() {
      this.$refs['delete-blocks-confirm'].hide();
    },
    async deleteBlocks() {
      this.isModalBusy = true;
      const formData = this.modalEditBlock.id;
      await this.$store.dispatch('DELETE_BLOCKS_ID', {
        formData,
        handler: () => {
          this.$notify({
            type: 'success',
            title: this.$i18n.t('alert.deleteBlocks'),
            text: this.sec2time(this.modalEditBlock.time_start_at),
          });
          this.hideModalEditBlocks();
          this.getBroadcastInfo(this.lastUsed === 'day' ? 'day' : 'update');
        },
        handlerError: (errors) => {
          errorsHandler(errors, this.$notify);
        },
      });
      this.isModalBusy = false;
      this.hideModalDeleteBlocksConfirm();
    },

    //modal AddEditProgram

    //autofill empty blocks fields from program release in Add mode
    modalEditProgramsDayFrom() {
      if (this.modalEditPrograms.type === 'add') this.modalEditPrograms.blocks.forEach((b) => (b.dayFrom = this.modalEditPrograms.dayFrom));
    },
    modalEditProgramsTimeFrom() {
      if (this.modalEditPrograms.type === 'add')
        this.modalEditPrograms.blocks.forEach((b) => {
          if (!b.timeFrom) b.timeFrom = this.modalEditPrograms.timeFrom;
        });
    },

    async showModalAddEditPrRelease(type, clickDate, event) {
      if (!this.canAddProgramRelease) return;
      this.modalLoading = true;
      this.$refs['add-edit-program'].show();
      this.modalEditPrograms.channels = this.selects.channels;
      if (!this.programs || (this.programs && this.programs.data.length === 0)) await this.$store.dispatch('GET_PROGRAMS', { per_page: 1000 });
      if (type === 'add') {
        this.modalEditPrograms.type = 'add';
        if (event) {
          this.getPosY(event);
          const timeOfClick = this.time2sec(this.timeOfClick(this.posY));
          let date = clickDate;
          if (typeof date === 'string') {
            this.modalEditPrograms.dayFrom = date;
            this.modalEditPrograms.timeFrom = timeOfClick;
            this.modalEditProgramsDayFrom();
            this.modalEditProgramsTimeFrom();
          }
        }
      } else {
        this.modalEditPrograms.type = 'edit';
        this.modalEditPrograms.selectedMode = 'program';
        this.hideContextModalProgram();
        this.modalEditPrograms.programs = this.programs.data.filter((pr) => pr.id === this.contextProgram.program_release_program_id)[0];
        this.modalEditPrograms.name = this.contextProgram.program_release_name;
        this.modalEditPrograms.dayFrom = this.contextProgram.program_release_broadcast_day;
        this.modalEditPrograms.timeFrom = this.contextProgram.program_release_start_interval;
        this.modalEditPrograms.duration = this.contextProgram.program_release_broadcast_duration;
      }
      this.modalLoading = false;
    },
    hideModalEditProgram() {
      this.$refs['add-edit-program'].hide();
    },

    showModalAddProgramConfirm() {
      this.$refs['add-program-confirm'].show();
    },
    hideModalAddProgramConfirm() {
      this.$refs['add-program-confirm'].hide();
    },

    addBlockRowInReleaseModal() {
      const prevElement = this.modalEditPrograms.blocks[this.modalEditPrograms.blocks.length - 1];
      this.modalEditPrograms.blocks.push({
        commercialType: prevElement ? prevElement.commercialType : '',
        dayFrom: prevElement ? prevElement.dayFrom : '',
        timeFrom: prevElement ? prevElement.timeFrom : '',
        duration: prevElement ? prevElement.duration : '',
        blockType: prevElement ? prevElement.blockType : '',
        auction_step_coeff: prevElement ? prevElement.auction_step_coeff : '',
      });
    },
    removeBlockRowInReleaseModal(data) {
      this.modalEditPrograms.blocks.splice(data, 1);
    },

    clearEditProgram() {
      this.modalEditPrograms = {
        type: 'add',
        selectedMode: 'program',
        channels: {},
        programs: {},
        programRelease: null,
        name: null,
        dayFrom: '',
        duration: '',
        timeFrom: '',
        blocks: [
          {
            commercialType: '',
            blockType: 1,
            dayFrom: '',
            timeFrom: '',
            duration: '',
            auction_step_coeff: this.defaultAuctionStepCoefficient(),
          },
        ],
      };
    },

    // complete duration from selected program
    selectedProgramInModal(program) {
      if (!(program && this.modalEditPrograms.type === 'add')) return;
      this.modalEditPrograms.duration = this.time2sec(program.duration);
    },

    async addEditProgramRelease() {
      const formData = {
        channel_id: this.modalEditPrograms.channels.id,
        program_id: this.modalEditPrograms.programs.id,
        name: this.modalEditPrograms.name || null,
        broadcast_day: this.modalEditPrograms.dayFrom,
        start_interval: this.modalEditPrograms.timeFrom,
        broadcast_duration: this.modalEditPrograms.duration,
      };
      if (this.modalEditPrograms.type === 'add') {
        await this.$store.dispatch('POST_PROGRAM_RELEASES', {
          formData,
          handler: (res) => {
            this.$notify({
              type: 'success',
              title: this.$i18n.t('alert.addPrograms'),
              text: `${this.modalEditPrograms.dayFrom} ${this.sec2time(this.modalEditPrograms.timeFrom)}, ${this.sec2time(this.modalEditPrograms.duration)}`,
            });
            this.modalEditPrograms.programRelease = res.data.data.id;
            if (this.modalEditPrograms.selectedMode === 'program') {
              this.getBroadcastInfo(this.lastUsed === 'day' ? 'day' : 'update');
              this.hideModalEditProgram();
              this.clearEditProgram();
            } else if (this.modalEditPrograms.selectedMode === 'programBlock') this.addBlocks();
          },
          handlerError: (errors) => errorsHandler(errors, this.$notify),
        });
      } else {
        //edit prRelease
        const programReleasesId = this.contextProgram.program_release_id;
        await this.$store.dispatch('PUT_PROGRAM_RELEASES', {
          programReleasesId,
          formData,
          handler: () => {
            this.$notify({
              type: 'success',
              title: this.$i18n.t('alert.editPrograms'),
              text: `${this.modalEditPrograms.dayFrom} ${this.sec2time(this.modalEditPrograms.timeFrom)}, ${this.sec2time(this.modalEditPrograms.duration)}`,
            });
            this.clearEditProgram();
            this.hideModalEditProgram();
            this.getBroadcastInfo(this.lastUsed === 'day' ? 'day' : 'update');
          },
          handlerError: (errors) => errorsHandler(errors, this.$notify),
        });
      }
    },
    async addBlocks() {
      for (const block of this.modalEditPrograms.blocks) {
        const formData = {
          channel_id: this.modalEditPrograms.channels.id,
          program_release_id: this.modalEditPrograms.selectedMode === 'programBlock' ? this.modalEditPrograms.programRelease : undefined,
          commercial_type_id: block.commercialType,
          block_type_id: block.blockType,
          auction_step_coeff: block.auction_step_coeff,
          broadcast_day: block.dayFrom,
          start_interval: block.timeFrom,
          duration: block.duration,
        };
        await this.$store.dispatch('POST_BLOCKS', {
          formData,
          handler: () => {
            this.$notify({
              type: 'success',
              title: this.$i18n.t('alert.addBlocks'),
              text: `${block.dayFrom} ${this.sec2time(block.timeFrom)}, ${block.duration}`,
            });
          },
          handlerError: (errors) => errorsHandler(errors, this.$notify),
        });
      }
      this.getBroadcastInfo(this.lastUsed === 'day' ? 'day' : 'update');
      this.hideModalEditProgram();
      if (this.modalEditPrograms.selectedMode === 'programBlock') this.modalEditPrograms.programRelease = null;
      this.clearEditProgram();
    },
    async AddProgram() {
      this.isModalBusy = true;
      if (this.modalEditPrograms.selectedMode === 'programBlock') await this.addEditProgramRelease();
      else if (this.modalEditPrograms.selectedMode === 'block') await this.addBlocks();
      else if (this.modalEditPrograms.selectedMode === 'program') await this.addEditProgramRelease();
      this.isModalBusy = false;
      this.hideModalAddProgramConfirm();
    },

    async EditProgram() {
      this.isModalBusy = true;
      this.modalEditPrograms.type = 'edit';
      this.modalEditPrograms.selectedMode = 'program';
      await this.addEditProgramRelease();
      this.isModalBusy = false;
    },
    //end modal EditProgram

    // modal delete program release
    async showModalDeleteProgramRelease() {
      this.$refs['delete-program-release-confirm'].show();
    },
    hideModalDeleteProgramReleaseConfirm() {
      this.$refs['delete-program-release-confirm'].hide();
    },
    async deleteProgramRelease() {
      this.isModalBusy = true;
      const formData = this.contextProgram.program_release_id;
      await this.$store.dispatch('DELETE_PROGRAM_RELEASES_ID', {
        formData,
        handler: () => {
          this.$notify({
            type: 'success',
            title: this.$i18n.t('alert.deletePrograms'),
            text: this.contextProgram.program_release_name,
          });
          this.hideContextModalProgram();
          this.getBroadcastInfo(this.lastUsed === 'day' ? 'day' : 'update');
        },
        handlerError: (errors) => {
          errorsHandler(errors, this.$notify);
        },
      });
      this.isModalBusy = false;
      this.hideModalDeleteProgramReleaseConfirm();
    },

    //Spots in block
    async showModalBlockSpots(block_id, prop_day, block_type, block_step) {
      if (!this.canReadSpotList) return;
      const dayBroadcastingSettings = this.gridBroadcastingSettings.find((e) => e.date === prop_day);
      // set closed state if Current User is AMA and day is closed for AMA or the same for Manager SH.
      this.blockFromClosedDay =
        (((dayBroadcastingSettings.is_closed & 1) === 1 && this.isCurrentUserAMA) || ((dayBroadcastingSettings.is_closed & 2) === 2 && this.isCurrentUserSH)) &&
        !this.canWorkInClosedDay;
      await Promise.all([
        !this.commercialVersionTypes || (this.commercialVersionTypes && this.commercialVersionTypes.data.length < 1)
          ? this.$store.dispatch('GET_COMM_VER_TYPES', { per_page: 1000 })
          : undefined,
        !this.placementType || (this.placementType && this.placementType.length < 1)
          ? this.$store.dispatch('GET_PLACEMENT_TYPE', { per_page: 1000 })
          : undefined,
        this.$store.dispatch('GET_BLOCK_SPOTS', block_id),
      ]);
      this.blockId = block_id;
      this.blockType = block_type;
      this.blockStep = block_step;
      this.$bvModal.show('block-spots-list');
    },

    async exportReport() {
      this.exporting = true;
      await this.$store.dispatch('GET_EXPORT_BROADCAST', {
        id: this.selects.channels.id,
        params: {
          format: 'xlsx',
          date_start_at: this.lastUsed === 'day' ? this.firstDays : this.firstDay,
          commercial_type_id: this.selects.commercial ? this.selects.commercial.id : null,
          block_type_id: this.selects.block ? this.selects.block.id : null,
          measurement_company_id: this.selects.measurements ? this.selects.measurements.id : null,
          target_audience_id: this.selects.target ? this.selects.target.id : null,
        },
        handler: (res) => {
          this.prepareAndDownloadFile(res);
        },
      });
      this.exporting = false;
    },

    async prepareDay(data) {
      this.isPreparingDay = true;
      const day = convertDate(data);
      this.$notify({
        type: 'success',
        title: this.$i18n.t('alert.wait'),
      });
      await this.$store.dispatch('POST_PREPARE_DAY', {
        channelId: this.selects.channels.id,
        formData: {
          days: [data],
        },
        handler: (res) => {
          const textRow = res.data
            ? `${this.$i18n.t('alert.foundProblems')}: ${res.data.conflict} \n
              ${this.$i18n.t('alert.resolvedProblems')}: ${res.data.conflictResolved}`
            : '';
          this.$notify({
            type: 'success',
            title: this.$i18n.t('alert.preparationDay') + ' ' + day,
            text: textRow,
            duration: 6000,
          });
          this.getBroadcastInfo(this.lastUsed === 'day' ? 'day' : 'update');
        },
        handlerError: (errors) => {
          errorsHandler(errors, this.$notify);
        },
      });
      this.isPreparingDay = false;
    },

    openCompactifyDayModal(day) {
      this.compactDay = day;
      this.isCompactFromDayStart = false;
      this.$bvModal.show('modal-confirm-compactify');
    },

    async compactifyDay(event) {
      if (this.broadcastStatus !== 'success' || !this.broadcastData?.data?.grid) return;
      this.isCompacting = true;
      let lastUsedEndInterval = this.BROADCAST_START_TIME_IN_SEC - 1 || 0; // -1 due to +1 sec later in entity edit
      let successCount = 0;

      const dayData = this.broadcastData.data.grid[this.compactDay];
      if (dayData) {
        for (const [index, el] of dayData.entries()) {
          let entityEndInterval = el.program_release_end_interval || el.blocks[0]?.block_end_interval;
          let isBreak = false;

          if (+index > 0 || this.isCompactFromDayStart) {
            const isEntityPrRelease = el.program_release_broadcast_day ? true : false;
            const entityStartInterval = isEntityPrRelease ? el.program_release_start_interval : el.blocks[0]?.block_start_interval;

            //check time difference between this and prev elements
            const timeDifference = entityStartInterval - lastUsedEndInterval;
            if (timeDifference > 1) {
              // edit entity with compensated time difference
              if (isEntityPrRelease) {
                const programReleasesId = el.program_release_id;
                await this.$store.dispatch('PUT_PROGRAM_RELEASES', {
                  programReleasesId,
                  formData: {
                    channel_id: this.selects.channels.id,
                    program_id: el.program_release_program_id,
                    name: el.program_release_name || undefined,
                    start_interval: lastUsedEndInterval + 1,
                    broadcast_duration: el.program_release_broadcast_duration,
                    broadcast_day: el.program_release_broadcast_day,
                  },
                  handler: (res) => {
                    entityEndInterval = res.data.end_interval;
                    successCount++;
                  },
                  handlerError: (errors) => {
                    errorsHandler(errors, this.$notify);
                    isBreak = true;
                  },
                });
              } else if (el.blocks[0]) {
                const blockId = el.blocks[0].block_id;
                await this.$store.dispatch('PUT_BLOCKS', {
                  blockId,
                  formData: {
                    channel_id: this.selects.channels.id,
                    program_release_id: el.blocks[0].block_program_release_id || undefined,
                    commercial_type_id: el.blocks[0].block_commercial_type_id,
                    block_type_id: el.blocks[0].block_type_id,
                    start_interval: lastUsedEndInterval + 1,
                    duration: el.blocks[0].block_duration,
                    broadcast_day: el.blocks[0].block_broadcast_day,
                  },
                  handler: (res) => {
                    entityEndInterval = res.data.end_interval;
                    successCount++;
                  },
                  handlerError: (errors) => {
                    errorsHandler(errors, this.$notify);
                    isBreak = true;
                  },
                });
              }
              //exit loop if any error has occurred during PUT request
              if (isBreak) break;
            }
          }
          //update endInterval of last used element
          lastUsedEndInterval = entityEndInterval;
        }
      }

      this.$notify({
        type: 'success',
        title: this.$i18n.t('alert.successfullyCompactified') + ' (' + convertDate(this.compactDay) + ')' + ': ' + successCount,
        duration: 5000,
      });
      if (successCount) this.getBroadcastInfo(this.lastUsed === 'day' ? 'day' : 'update');
      //use nextTick because the same tick prevents modal from closing
      this.$nextTick(() => {
        this.$bvModal.hide('modal-confirm-compactify');
      });
      this.isCompacting = false;
    },

    async compactifySingle(param) {
      // confirm Modal
      const modalResult = await this.$bvModal
        .msgBoxConfirm(this.$i18n.t('table.confirmPullToPreviousBlockRelease'), {
          title: this.$i18n.t('broadcastSideBar.compactify'),
          size: 'sm',
          okTitle: this.$i18n.t('table.yes'),
          cancelTitle: this.$i18n.t('table.no'),
          autoFocusButton: 'ok',
          id: 'confirm-open-close-month-modal',
        })
        .catch((err) => {});
      if (!modalResult) return;

      let elIndex = null;
      let dayData = null;
      let elementStartInterval = null;

      if (param === 'release') {
        this.$refs['context-modal-program'].hide();
        if (!this.contextProgram) return;
        elementStartInterval = this.contextProgram.program_release_start_interval;
        dayData = this.broadcastData.data.grid[this.contextProgram.program_release_broadcast_day];
        if (!dayData) return;
        elIndex = dayData.indexOf(this.contextProgram);
      } else if (param === 'block') {
        this.hideModalEditBlocks();
        dayData = this.broadcastData.data.grid[this.modalEditBlock.date_start_at];
        elementStartInterval = this.modalEditBlock.time_start_at;
        if (!dayData) return;
        for (let [index, el] of dayData.entries()) {
          if (
            el.program_release_id === this.modalEditBlock.programReleasesValue &&
            el.blocks &&
            el.blocks[0] &&
            el.blocks[0].block_id === this.modalEditBlock.id
          ) {
            elIndex = index;
            break;
          }
        }
      }
      //common part
      if (elIndex < 1) {
        this.$notify({
          type: 'warning',
          title: 'There is no block/program release before it',
        });
        return;
      }
      const prevElementEndInterval = dayData[elIndex - 1]?.program_release_end_interval || dayData[elIndex - 1]?.blocks[0]?.block_end_interval || undefined;
      if (!prevElementEndInterval) return;
      const newStartTimeInterval = prevElementEndInterval + 1;

      if (param === 'release') {
        const formData = {
          channel_id: this.selects.channels.id,
          program_id: this.contextProgram.program_release_program_id,
          name: this.contextProgram.program_release_name || undefined,
          broadcast_day: this.contextProgram.program_release_broadcast_day,
          start_interval: newStartTimeInterval,
          broadcast_duration: this.contextProgram.program_release_broadcast_duration,
        };
        const programReleasesId = this.contextProgram.program_release_id;
        await this.$store.dispatch('PUT_PROGRAM_RELEASES', {
          programReleasesId,
          formData,
          handler: () => {
            this.$notify({
              type: 'success',
              title: this.$i18n.t('alert.successfullyCompactified'),
            });
            this.clearEditProgram();
            this.getBroadcastInfo(this.lastUsed === 'day' ? 'day' : 'update');
          },
          handlerError: (errors) => errorsHandler(errors, this.$notify),
        });
      } else if (param === 'block') {
        const formData = {
          channel_id: this.selects.channels.id,
          program_release_id: null,
          commercial_type_id: this.modalEditBlock.commercialValue.id,
          block_type_id: this.modalEditBlock.blockValue.id,
          broadcast_day: this.modalEditBlock.date_start_at,
          start_interval: newStartTimeInterval,
          duration: this.modalEditBlock.duration,
        };
        const blockId = this.modalEditBlock.id;
        await this.$store.dispatch('PUT_BLOCKS', {
          blockId,
          formData,
          handler: () => {
            this.$notify({
              type: 'success',
              title: this.$i18n.t('alert.successfullyCompactified'),
            });
            this.clearModalEditBlocksFields();
            this.getBroadcastInfo(this.lastUsed === 'day' ? 'day' : 'update');
          },
          handlerError: (errors) => errorsHandler(errors, this.$notify),
        });
      }
    },
  },
};
</script>

<style lang="sass" src="../assets/sass/broadcast.sass"></style>

<style lang="sass">
#drag-time-popover
  position: fixed
  top: 100px
  left: 100px
  padding: 0.25rem 0.5rem
  background-color: white
  border-bottom-width: 2px !important
  z-index: 1000
  color: black
  border-radius: 0.25rem 0.25rem 0 0
  transition: all 0.1s
  pointer-events: none // to prevent stuck on dragover event
</style>
